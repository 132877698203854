<template>
  <div class="projects_list">
    <h1>Aktuelles</h1>
    <div class="project" v-for="news in store.news" :key="news.id" v-on:click="showNewsDetail(news.id)">
      <!-- header -->
      
      <div class="project__header_content">
        <h4>{{store.formatDate(news.postDate)}}</h4>
        <h2>{{news.headline}}</h2>
      </div>
      <span class="project__content" v-html="textEllipsis(news.pageContent, 200)"></span>
      <a  class="next-link">weiterlesen</a>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'News',
  metaInfo: {
    title: 'Second Bandshirt - Neuigkeiten',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    showNewsDetail(newsIndex){
      this.store.selectedNewsIndex = newsIndex;
      this.$router.push({ path: 'newsDetail', query: { newsId: newsIndex } })
    },
    textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
      if (str.length > maxLength) {
        switch (side) {
          case "start":
            return ellipsis + str.slice(-(maxLength - ellipsis.length));
          case "end":
          default:
            return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
      }
      return str;
    }
  }
}
</script>

<style lang="scss">

.project {
  color: $color-text;

  
}

.prohect-list{
  h1{
    color: $color-text;
    margin-top: 74px;
    margin-bottom: 24px;
  }
}
</style>
